import React from 'react';

import { Auth0Context } from "@auth0/auth0-react";

import {
    Container,
    Row,
    Col,
    Card,
    Spinner,
} from 'react-bootstrap';

import api_url from "../api_url";
import InvokeIDAAdminKnowledge from './InvokeIDAAdminKnowledge';
import InvokeIDAAdminPromptQC from './InvokeIDAAdminPromptQC';
import InvokeIDAAdminPromptQA from './InvokeIDAAdminPromptQA';

class InvokeIDAAdmin extends React.Component {
    static contextType = Auth0Context;

    constructor(props) {
        super(props);

        this.state = {
            jrrToken: null,
            knowledge: "",
            alertVisible: false,
            alertMessage: "",
        };

        this.submitKnowledge = this.submitKnowledge.bind(this);
        this.setKnowledge = this.setKnowledge.bind(this);
    }

    componentDidMount() {
        const { getAccessTokenSilently } = this.context;
        getAccessTokenSilently({
            audience: api_url,
        }).then((t) => {
            this.setState({ jrrToken: "Bearer " + t });
            this.initializeData();
        }).catch((error) => {
            console.log(error);
        });
    }

    componentWillUnmount() {
    }

    initializeData() {
        this.getKnowledge();
    }

    getKnowledge() {
        const options = {
            mode: 'cors',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.state.jrrToken,
                'Invoke-Action': 'ida_knowledge'
            }
        };

        fetch(
            `${api_url}/ida/institution`,
            options
        ).then(
            response => response.text()
        ).then(
            (data) => {
                this.setState({
                    knowledge: data,
                });
            }
        ).catch(
            (error) => console.log(error)
        );
    }

    submitKnowledge() {
        const options = {
            mode: 'cors',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.state.jrrToken,
                'Invoke-Action': 'ida_knowledge'
            },
            body: this.state.knowledge,
        };

        this.setState({
            alertVisible: true,
            alertMessage: (
                <div>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                    &nbsp;&nbsp;Updating IDA...
                </div>
            ),
        });

        fetch(
            `${api_url}/ida/institution`,
            options
        ).then(
            response => response.text()
        ).then(
            (data) => {
                this.setState({
                    alertVisible: true,
                    alertMessage: "Knowledge Successfully Updated!",
                });
                setTimeout(
                    () => {
                        this.setState(
                            {
                                alertVisible: false,
                                alertMessage: "",
                            }
                        );
                    },
                    3000
                );
            }
        ).catch(
            (error) => console.log(error)
        );
    }

    setKnowledge(q) {
        this.setState({ knowledge: q.target.value });
    }

    render() {
        return (
            <Container>
                <Card>
                    <Card.Body>
                        <Card.Title>IDA Invoke-Internal Administration</Card.Title>
                        <Container>
                            <Row className="my-2">
                                <Col>
                                    <InvokeIDAAdminKnowledge
                                        token={this.state.jrrToken}
                                    />
                                </Col>
                            </Row>
                            <Row className="my-2">
                                <Col>
                                    <InvokeIDAAdminPromptQC
                                        token={this.state.jrrToken}
                                    />
                                </Col>
                                <Col>
                                    <InvokeIDAAdminPromptQA
                                        token={this.state.jrrToken}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </Card.Body>
                </Card>
            </Container>
        );
    }
}

export default InvokeIDAAdmin;